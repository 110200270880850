@import url('https://fonts.googleapis.com/css2?  family=Montserrat:ital,wght@0,100;0,200;1,100;1,200&display=swap');

.App {
  background-color: white;
  font-family: 'Montserrat Ligth',Helvetica,Arial,Lucida,sans-serif;
}

.Auth-form-container-full {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75vw;
}

.Auth-form {
  width: 580px;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: white;
}

.title-primary {
  color: #fe850e;
}

.title-secondary {
  color: #f6df37
}

.title-lima {
  color: #d3df0f
}

.title-purple {
  color: #5a1683
}

.title-disable {
  color: #bdb4c1;
}

/*.icon{*/
/*  color: #5a1683*/
/*}*/

.editing-border {
  border: 1px solid #5a1683 !important; /* Aquí puedes usar el color que desees */
}

.btn-primary {
  background-color: #fe850e;
  border-color: #fe850e
}

.btn-primary:hover {
  background-color: #fe850e !important;
  border-color: #fe850e !important;

}

.btn-primary:focus {
  background-color: #5a1683 !important;
  border-color: #5a1683 !important;

}

.btn-primary:active {
  background-color: #5a1683 !important;
  border-color: #5a1683 !important;
}

.toggle-btn {
  outline: none;
}

.border-naranja{
  border-radius: 5px ;
  border: 2px solid  #fe850e !important;
}

/* Estilos cuando el interruptor está activado (:checked) */
.toggle-btn:checked {
  background-color: #5a1683; /* Cambia a tu color deseado cuando está activado */
  border-color: #5a1683; /* Cambia a tu color deseado cuando está activado */
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.eye-icon{
  position: relative;
  top: -38px;
  float: right;
  /* right: 10px; */
  cursor: pointer;
}

.vertical-lr {
  writing-mode: sideways-lr;
  vertical-align: middle;
}

.noBorder {
  border: none;
  outline: none;
}

img {
    pointer-events: none;
}

.headerVisible {
  position: sticky;
  top: 0;
  z-index: 1;
}

.columnVisible {
  position: sticky;
  left: 0;
  z-index: 2;
}

.RowStyle {
  display: inline-block;
}

.ColumnStyle {
  display: table-cell;
  float: none;
}

.TableStyle {
  display: table;
  padding-left: 0px;
  padding-right: 0px;
}

.tableFont {
  font-family: 'Montserrat Ligth',Helvetica,Arial,Lucida,sans-serif;
  color: white !important;
  background-color: #fe850e !important;
  border: 1px solid white;
}

.img-wrap {
  position: relative;
}
.img-wrap .close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 100;
  color: #fe850e !important;
}

.img-wrap .edit {
  position: absolute;
  top: 5px;
  right: 130px;
  z-index: 100;
  color: #fe850e !important;
}

.modal-font {
  font-family: 'Montserrat Ligth',Helvetica,Arial,Lucida,sans-serif !important;
}

.alert-font {
  font-family: 'Montserrat Ligth',Helvetica,Arial,Lucida,sans-serif !important;
  color: #5a1683 !important;
}

.alert-backgroud {
  background-color: #f6df37 !important;
}
